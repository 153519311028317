import { SetStateAction } from "react";
import { updateCqOrganization, updateCwOrganization } from "../../../api/internal";
import {
  ExtendedOrganization,
  ExtendedOrganizationWithMapiOrg,
  Organization,
} from "../../../api/schemas/organization";
import { AppState, AppStateAction } from "../../contexts/app/reducer";
import { setCxOrganzation } from "../../shared/state-management";
import { Toast } from "../../shared/toast";
import { captureAndDisplayError } from "../../shared/util";

export async function setHieOrganizationActive({
  cxId,
  state,
  dispatch,
  setOrganization,
  hie,
  organization,
  toast,
  setRunning,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  setOrganization: React.Dispatch<SetStateAction<ExtendedOrganization | undefined>>;
  hie: "commonwell" | "carequality";
  organization: ExtendedOrganizationWithMapiOrg;
  toast: Toast;
  setRunning: React.Dispatch<SetStateAction<boolean>>;
}) {
  setRunning(true);
  const { updateFunc, activeFieldName, orgFieldName } = getHieSpecificConfig(hie);
  const newActive = !organization.organization[activeFieldName];
  try {
    await updateFunc(cxId, organization.organization.oid, { active: newActive });
    toast.success({ title: "Organization saved." });
    setCxOrganzation({
      cxId,
      state,
      dispatch,
      setter: setOrganization,
      organization: {
        ...organization.organization,
        [orgFieldName]: {
          ...organization[orgFieldName],
          active: newActive,
        },
        [activeFieldName]: newActive,
      },
    });
  } catch (error) {
    // TODO Check for timeout and flag as warning (#1940)
    captureAndDisplayError({
      error,
      msg: "Failed to update organization",
      context: "setHieOrganizationActive",
      captureMsg: `updateFunc failed`,
      toast,
      extras: {
        cxId,
        hie,
        orgId: organization.organization.id,
        hieOrgId: organization.organization.oid,
      },
    });
  }
  setRunning(false);
}

function getHieSpecificConfig(hie: "commonwell" | "carequality"): {
  updateFunc: typeof updateCwOrganization | typeof updateCqOrganization;
  activeFieldName: keyof Organization;
  orgFieldName: keyof ExtendedOrganization;
} {
  if (hie === "commonwell") {
    return {
      updateFunc: updateCwOrganization,
      activeFieldName: "cwActive",
      orgFieldName: "cwOrganization",
    };
  } else {
    return {
      updateFunc: updateCqOrganization,
      activeFieldName: "cqActive",
      orgFieldName: "cqOrganization",
    };
  }
}
