import { SetStateAction } from "react";
import { updateCqFacility, updateCwFacility } from "../../../api/internal";
import { ExtendedFacility, Facility } from "../../../api/schemas/facility";
import { FacilitiesMap } from "../../../domain/facility";
import { AppState, AppStateAction } from "../../contexts/app/reducer";
import { setCxFacilities } from "../../shared/state-management";
import { Toast } from "../../shared/toast";
import { captureAndDisplayError, filterFacilities } from "../../shared/util";

export async function setHieFacilityActive({
  cxId,
  state,
  dispatch,
  setFacilities,
  hie,
  facility,
  facilitiesMap,
  toast,
  setRunning,
}: {
  cxId: string;
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
  setFacilities: React.Dispatch<SetStateAction<FacilitiesMap | undefined>>;
  hie: "commonwell" | "carequality";
  facility: ExtendedFacility;
  facilitiesMap: FacilitiesMap;
  toast: Toast;
  setRunning: React.Dispatch<SetStateAction<boolean>>;
}) {
  setRunning(true);
  const { updateFunc, activeFieldName, facilityFieldName } = getHieSpecificConfig(hie);
  const newActive = !facility.facility[activeFieldName];
  try {
    await updateFunc(cxId, facility.facility.id, facility.facility.oid, { active: newActive });
    toast.success({ title: "Facility saved." });
    const existingFacilities = filterFacilities(facilitiesMap, facility.facility.id);
    setCxFacilities({
      cxId,
      state,
      dispatch,
      setter: setFacilities,
      facilities: [
        ...existingFacilities,
        {
          ...facility,
          [facilityFieldName]: {
            ...facility[facilityFieldName],
            active: newActive,
          },
          facility: {
            ...facility.facility,
            [activeFieldName]: newActive,
          },
        },
      ],
    });
  } catch (error) {
    // TODO Check for timeout and flag as warning (#1940)
    captureAndDisplayError({
      error,
      msg: "Failed to update facility",
      context: "setHieFacilityActive",
      captureMsg: `updateFunc failed`,
      toast,
      extras: {
        cxId,
        hie,
        facilityId: facility.facility.id,
        hieOrgId: facility.facility.oid,
      },
    });
  }
  setRunning(false);
}

function getHieSpecificConfig(hie: "commonwell" | "carequality"): {
  updateFunc: typeof updateCwFacility | typeof updateCqFacility;
  activeFieldName: keyof Facility;
  facilityFieldName: keyof ExtendedFacility;
} {
  if (hie === "commonwell") {
    return {
      updateFunc: updateCwFacility,
      activeFieldName: "cwActive",
      facilityFieldName: "cwFacility",
    };
  } else {
    return {
      updateFunc: updateCqFacility,
      activeFieldName: "cqActive",
      facilityFieldName: "cqFacility",
    };
  }
}
